import React, { useState, useEffect } from "react";
import Logo from '../assets/TempLogo3.svg'
import '../App.css'; 
// import LanguageLogo from '../assets/UKLanguageFlag.svg'
import { Link } from "react-router-dom";

export const Navigation = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 10000); // 10 seconds

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts 
  }, []);

  return (
    
      <nav id="menu" className={`navbar navbar-default navbar-fixed-top ${isVisible ? 'visible' : ''}`}>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
        <a href="/"><img className="logo-img" src={Logo} /></a> 
        </div>
        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right navhover">
            <li>
              <a href="/#kyaapp" className="page-scroll">
              About KYA App
              </a>
            </li>
            <li>
              <a href="/#whoisall" className="page-scroll">
                Who is all?
              </a>
            </li>
            <li>
              <a href="/#contact" className="page-scroll">
                Contact Us
              </a>
            </li>
            <li>
              <button className="btn btn-primary nav-btn">
                Download app
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
