import React from 'react';
import '../CSS/whoisall.css';  // Importing the CSS file for this component
import Corporate2 from '../assets/coporate2.svg';
import Elementary2 from '../assets/elementary2.svg';
import Individual2 from '../assets/Individual2.svg';
import Relationship2 from '../assets/relationship2.svg';
import SelfConfidence2 from '../assets/self_confidence2.svg';

const contentData = [
  {
    title: 'Individuals',
    description: `Feeling perplexed, nervous, furious, angry, anxious, frustrated, shame, disturbed, helpless, guilt?
    KnowYouAll is a 24/7 friend and coach in your hand. Unlock your thoughts and feelings to an empathetic, nonjudgmental friend for emotional support, and learn from an AI coach how to handle and control emotions well. Practice expressing emotions assertively with a virtual coach in the VR world.`,
    image: Individual2,
    color: "#6C6D52",
  },
  {
    title: 'Relationships',
    description: `Discover a deeper understanding of yourself and others in your life with KnowYouAll, an AI-powered app that helps you manage your emotions and navigate relationship conflicts with real-life solutions. Open the door to get to know the significant person in your life with our app. Learn to connect, understand and relate to others' perspectives through VR role-playing. Enhance your overall being with KnowYouAll app.`,
    image: Relationship2,
    color: "#7B6168",
  },
  {
    title: 'Corporate Industries',
    description: `KnowYouAll  can revolutionize industries by providing instant global access and real time empathetic virtual customer service agent. Our app with AI emotion recognition technology is a bridge between companies and customers. Transform customer service strategies, training, product development, brand recognitions, reputation, and personalized campaign marketing using customer reviews. Gain a competitive edge by understanding and catering to customer needs seamlessly.`,
    image: Corporate2,
    color: "#6F7783",
  },
  {
    title: 'Elementary Schools',
    description: `KnowYouAll, combining AI and VR technology and Cognitive Behavioral techniques, empowers educators to identify bullying and prevent it before it escalates, builds collaboration between teachers and parents. It will be a tool to boost students' confidence, understand and manage their emotions, reduce aggressiveness effectively.`,
    image: Elementary2,
    color: "#6C6D52",
  },
  {
    title: 'In Your Home',
    description: `Experience emotional development and strengthen parent-child bonds with KnowYouAll bedtime stories. Designed to develop empathy, morals, and positivity, our handpicked stories feature fascinating heroic characters from diverse cultures. Let your child immerse in these stories, fostering understanding, empathy, and compassion from an early age, laying the foundation for healthy social interactions and self-awareness.`,
    image: SelfConfidence2,
    color: "#533940",
  }
];

const ContentSection = ({ title, description, image, color }) => {
  const headingStyle = {
    color: color, // Use the color prop to set the heading color
  };

  return (
    <div id="whoisall">
      <div className="content-section">
        <div className="icon-placeholder">
          <img src={image} alt={`${title} icon`} />
        </div>
        <h2 style={headingStyle}>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

const WhoisAll = () => (
  <div className="whoisall" id='whoisall'>
    <div className='whoisall-wrapper'>
    <h2>Who is All?</h2>
    <div className="content-container">
      {contentData.map((section, index) => (
        <ContentSection
          key={index}
          title={section.title}
          description={section.description}
          image={section.image}
          color={section.color} // Pass the color prop to ContentSection
        />
      ))}
    </div>
    <hr />
    </div>
  </div>
);

export default WhoisAll;
