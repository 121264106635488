import React, { useEffect, useRef } from 'react';
import '../CSS/IconDisplay.css'; // Import the CSS file for this component
import icon from '../assets/PlayIcon.png'; // Adjust the path according to your project structure
import backgroundVideo from '../assets/banner-video.mp4';
import '../CSS/video.css';

const Banner = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    // Ensure autoplay by manually triggering it after a short delay
    const videoElement = videoRef.current;

    const playVideo = () => {
      if (videoElement) {
        videoElement.muted = true; // Ensure the video is muted
        videoElement.play().catch((error) => {
          console.log('Autoplay prevented:', error);
        });
      }
    };

    // Play the video after the component mounts
    playVideo();

    // If autoplay is blocked, play the video on user interaction
    const handleUserInteraction = () => {
      playVideo();
    };

    document.addEventListener('click', handleUserInteraction);

    return () => {
      document.removeEventListener('click', handleUserInteraction);
    };
  }, []);

  return (
    <div className="banner-container">
      {/* Icon Display */}
      {/* <div className="icon-display">
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }} className="icon-display-img">
          <img src={icon} alt="Icon" className="play-icon" />
        </div>
      </div> */}

      {/* Video Player */}
      <div className="video-player">
        <video
          ref={videoRef}
          className="responsive-video"
          autoPlay muted
          playsInline
          loop
          preload="auto"
        >
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Banner;
