import React, { useState, useEffect, useRef } from 'react';
import '../CSS/ContactForm.css';
import EmailIcon from '../assets/email_icon.svg';
import MobileImagePng from '../assets/Contact-us.png';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    message: '',
    agree: false,
  });

  const [labelVisibility, setLabelVisibility] = useState({
    first_name: false,
    last_name: false,
    email: false,
    message: false,
  });

  const [data, setData] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [fadeOut, setFadeOut] = useState(false);
  const captchaRef = useRef(null)

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));

    if (value !== '') {
      setLabelVisibility((prevVisibility) => ({
        ...prevVisibility,
        [name]: true,
      }));
    } else {
      setLabelVisibility((prevVisibility) => ({
        ...prevVisibility,
        [name]: false,
      }));
    }

    if (name === 'email') {
      setEmailErrorMessage('');
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Email pattern validation
   // const emailPattern = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(formData.email)) {
      setEmailErrorMessage('Please enter a valid email address');
      return;
    }

    if (!captchaValue) {
      setResponseMessage('Please complete the CAPTCHA');
      setShowResponseMessage(true);
      return;
    }

    const postData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      message: formData.message,
      agree: formData.agree,
      'g-recaptcha-response': captchaValue,
    };

    try {
      const response = await fetch('https://kya-engine.actionfi.com/kya-web/contact-us/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      const result = await response.json();
      setData(result);
      setResponseMessage('Message sent successfully');
      setShowResponseMessage(true);
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('An error occurred. Please try again later.');
      setShowResponseMessage(true);
    }
  };

  useEffect(() => {
    if (showResponseMessage) {
      const timer = setTimeout(() => {
        setFadeOut(true);
      }, 6000); // Start fade-out after 6 seconds

      const hideTimer = setTimeout(() => {
        setShowResponseMessage(false);
        setFadeOut(false); // Reset fade-out for future messages
      }, 9000); // Hide after fade-out

      return () => {
        clearTimeout(timer);
        clearTimeout(hideTimer);
      };
    }
  }, [showResponseMessage]);

  return (
    <div className="contact-container" id="contact">
      <div className="contact-wrapper">
        <div className="contact-form">
          <h4>Contact Us</h4>
          <h2>Let's chat, reach out to us.</h2>
          <p>Please check how KYA app can be applicable to you. We can make it happen.</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="form-div">
                <label className={`form-label ${labelVisibility.first_name ? 'visible' : ''}`}>
                  First Name
                </label>
                <input
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  value={formData.first_name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-div">
                <label className={`form-label ${labelVisibility.last_name ? 'visible' : ''}`}>
                  Last Name
                </label>
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  value={formData.last_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-div">
              <label className={`form-label ${labelVisibility.email ? 'visible' : ''}`}>
                Email Address
              </label>
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={handleChange}
                pattern="/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;" //"^[a-zA-Z0-9._%+-]+@gmail\.com$"
                title="Email should be correct format"
                required
              />
              {/* {emailErrorMessage && <span className="error-message">{emailErrorMessage}</span>} */}
            </div>
            <div className="form-div">
              <label className={`form-label ${labelVisibility.message ? 'visible' : ''}`}>
                Message
              </label>
              <textarea
                name="message"
                placeholder="Leave us a message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="form-group">
              <input
                type="checkbox"
                name="agree"
                checked={formData.agree}
                onChange={handleChange}
                required
              />
              <label>I have read and agree to knowyouall's Privacy Policy</label>
              
            </div>
            <div className="recaptcha-container">
              <ReCAPTCHA
                name="recaptcha"
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
                onChange={handleCaptchaChange}
              />
            </div>
            <button type="submit">Send message</button>
            {showResponseMessage && (
              <div className={`response-message ${fadeOut ? 'fade-out' : ''}`}>
                {responseMessage}
              </div>
            )}
          </form>
        </div>
        <div className="contact-info">
          <img src={MobileImagePng} alt="Know You All" className="phone-image" />
          <div className="contact-details">
            <div className="contact-method">
              <img src={EmailIcon} alt="Email" className="contact-icon" />
              <div>
                <h4>Email</h4>
                <p className="contact-email">
                  <a href="mailto:contact@knowyouall.com">contact@knowyouall.com</a>
                </p>
              </div>
            </div>
            {/* <div className="contact-method">
              <img src={PhoneIcon} alt="Phone" className="contact-icon" />
              <div>
                <h4>Phone</h4>
                <p></p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
