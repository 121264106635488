import React from 'react';
import '../CSS/Footer.css'; // Assuming you have a CSS file for styling
import footerLogo from '../assets/TempLogo3.svg';
import twitterlogo from "../assets/twitterlogo1.png"
import instagramlogo from '../assets/instagramlogo1.png'
import gmaillogo from '../assets/gmaillogo.png'
const Footer = () => {
  return (
    <footer className="footer">
      <div className='footer-wrapper'>
      <div className="footer-container">
        <div className="footer-logo">
          {/* <h2 className="footer-logo">Know You All</h2> */}
          <img src={footerLogo} />
        </div>
        <div className="footer-data">
        {/* <div className="footer-section">
          <h3>Connect</h3>
          <p><a href="mailto:contact@knowyouall.com">contact@knowyouall.com</a></p>
          <p><a href="tel:+025283249234"></a></p>
       
          <h3 className='footer-h3'>Let's Connect</h3>
        </div> */}
        {/* <hr /> */}
        <div className="footer-section">
          <h3>Company</h3>
          <p><a href="/privacy-policy" className="footer-link">Privacy Policy</a></p>
          <p><a href="/terms-of-use" className="footer-link">Terms of Use</a></p>
        </div>
        </div>
      </div>
      <hr class="footer-underline" />
      <div className='footer-bottom-container'>
        <div className="footer-bottom">
            <a href="https://www.instagram.com/knowyouall3?igsh=MWl2NHhqcWx4NGxjcA==" className="footer-social" target='blank_'> <img src={instagramlogo} alt="Instagram" style={{ width: '20px', height: '20px'}} /></a>
            <a href="https://x.com/knowall355?s=21&t=Z9n1KFoq4XzTEi5o0klx-Q" className="footer-social" target='blank_'> <img src={twitterlogo} alt="X" style={{ width: '20px', height: '20px' }} /></a>
            <a href="mailto:contact@knowyouall.com" className="footer-social" target='blank_'> <img src={gmaillogo} alt="X" style={{ width: '20px', height: '20px' }} /></a>
        </div>
        <div className="footer-copyright">
            <p>&copy;2024 KnowYouAll. All Rights Reserved</p>
        </div>
      </div>
      </div>
      
    </footer>
  );
}

export default Footer;
