import React from 'react';
import '../CSS/KyaApp.css'; // Import the CSS file
import AboutLogo from '../assets/About_KYA.svg';
import AppleLogo from '../assets/Applelogo.svg';

const KyaApp = () => {
  return (
    <div className="kya-container" id='kyaapp'>
      <div className="kya-content">
        <div className="kya-images">
          <img src={AboutLogo} alt="Phone 1" className="phone-image-about" />
        </div>
        <div className="kya-text">
          <h2>About KYA App</h2>
          <p>
          Wherever we are, whatever we do, wherever we go, emotions are with us. KnowYouAll helps you control emotions in a healthy way before emotions control you. Whether it is individual, relationships, corporate and school, emotional awareness and clarity are crucial to connecting to others. KnowYouAll is 24/7 friend and coach to be with you to travel on this mental trip with cognizance and clarity.
          </p>
          <p className='kya-text-p getourapp'>Get Our App On App Store</p>
          <a href="https://www.apple.com/app-store/" className="app-store-link">
            <img src={AppleLogo} alt="App Store" />
          </a>
        </div>
      </div>
      <hr class="about-underline" />
    </div>
  );
};

export default KyaApp;
