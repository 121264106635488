import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigation } from "./components/navigation";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import Contact from './components/contact';
import AboutKyaApp from './components/aboutapp';
import Whoisall from './components/whoisall';
import Banner from './components/banner';
import Footer from './components/footer';
import TermsOfUse from './components/termsofuse';
import PrivacyPolicy from './components/privacypolicy';


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
      <div>
        <Navigation />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Banner />
                  <AboutKyaApp />
                  <Whoisall />
                  <Contact />
                </>
              }
            />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        
        <Footer />
      </div>
  );
};

export default App;
