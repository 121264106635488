import React from 'react';
import { Link } from 'react-router-dom';
import '../CSS/termsofuse.css';

const TermsOfUse = () => {
  return (
    <div className='termsofuse'>
      <center><h1>Welcome to KnowYouAll</h1></center>
      <p>
            Thank you for visiting KnowYouAll website, the only AI powered app with VR to travel with
        you as a friend and coach when you take a mental trip to the world of self-awareness and
        emotional intelligence. This is your journey to improved emotional wellbeing. KnowYouAll
        will understand you, recognize the emotion that has become overwhelming for you, guide you to
        understand yourself, support you with knowledge, provide you the tools and techniques to
        enhance wellbeing in your life, handle challenges in your daily life with clear mind and
        knowledge. These Terms of Service are designed to ensure your understanding of your rights and
        responsibilities while using KnowYouAll’s services.
      </p>
      <h3>1. Acceptance of Terms</h3>
      <p>
            By using KnowYouAll’s services, including the AI Powered facial, verbal and text chatbot,
        cognitive-behavioral therapy (CBT) exercises, you agree to comply with and be bound by these
        Terms of Service. Please take the time to carefully review and understand these terms. If you do
        not agree with these terms, we kindly ask you not to use our services.
      </p>
      <h3>2. Age Requirement and Consent</h3>
      <p>
            2.1 User Age Limitation: KnowYouAll’s services are intended for individuals who are at least
        18 years old. If you are under the age of 18, you may only use our services with the consent of a
        parent or legal guardian. Anyone under the age of 13 are not allowed to use KnowYouAll.
      </p>
      <h3>3. Your KnowYouAll Account</h3>
      <p>
            3.1 User Account Creation: To access our services, you will need to create a user account
        (“User Account”). By creating a User Account, you agree to provide accurate and complete
        information during the registration process. Your User Account is your personal space within
        KnowYouAll.
      </p>
      <p>
            3.2 Account Security: You are responsible for maintaining the security and confidentiality of
        your User Account credentials. Do not share your username or password with others. Know You
        All reserves the right to deny access, suspend, or terminate User Accounts for any reason, with
        or without notice.
      </p>
      <h3>4. KnowYouAll’s Services</h3>
      <p>
      4.1 Facial, Voice and Text Chatbot: KnowYouAll offers a three interface chatbot designed to
engage in conversations with you, providing general guidance, support, and information related
to emotional wellbeing. The chatbot is not a substitute for professional medical or psychological
advice. It is meant to offer assistance based on your inputs and principles of wellbeing.
      </p>
      <p>
      4.2 Cognitive-Behavioral Therapy (CBT) Exercises: KnowYouAll provides access to
cognitive-behavioral therapy (CBT) exercises, which are self-help tools designed to assist you in

managing your thoughts, emotions, and behaviors. These exercises are intended for personal
growth and wellbeing and should not replace professional therapy when needed.
      </p>
      <p>
      4.3 Content Accuracy: While we strive to provide accurate and up-to-date information, Know
You All does not guarantee the accuracy, completeness, or suitability of the content provided
through its services. You should consult with qualified professionals for specific advice or
concerns.
      </p>
      <p>
      4.4 No Substitute for Professional Help: KnowYouAll’s services are not a substitute for
professional medical, psychological, or psychiatric advice, diagnosis, or treatment. If you have or
suspect you may have a serious mental health condition or any other illness, please seek
assistance from a qualified healthcare provider.
      </p>
      <h3>5. Privacy and Data Handling</h3>
      <p>
      5.1 Privacy Policy: Protecting your privacy is of utmost importance to KnowYouAll. Please
review our Privacy Policy to understand how we collect, use, and protect your personal
information in connection with our services.
      </p>
      <h3>6. In-App Purchases</h3>
      <p>
      6.1 Premium Subscriptions: KnowYouAll is available through premium subscriptions, which
can be activated and paid for within the app stores. Prices may vary by location. Subscriptions
are auto-renewable unless canceled at least 24 hours before the end of the current subscription
period. Subscription charges are non-refundable.
      </p>
      <h3>7. Disclaimers of Information and Limitation of Liability</h3>
      <p>
      7.1 Use of KnowYouAll: Your use of KnowYouAll, its services, and any information
provided is at your own risk. KnowYouAll Ltd., its affiliates, employees, agents, and third-party
content providers do not warrant that the services will be uninterrupted, error-free, or that the
results obtained from the services will be accurate, reliable, or suitable for your specific needs.
      </p>
      <p>
      7.2 As-Is Basis: KnowYouAll’s services are provided on an “as-is” basis without warranties of
any kind, whether express or implied, including but not limited to warranties of title,
merchantability, fitness for a particular purpose, and non-infringement. Some jurisdictions do not
allow the exclusion of certain warranties; therefore, this provision may not apply to you.
      </p>
      <p>
      7.3 User Conduct: KnowYouAll is not responsible for the conduct of its users or third parties.
You acknowledge that any content generated by other users or third parties that appears on or
through KnowYouAll is their responsibility, and any risks associated with such content rest
solely with you.
      </p>
      <p>
      7.4 Limitation of Liability: In no event shall KnowYouAll, subsidiaries, affiliates, employees,
agents, third-party content providers, or licensors be liable for any direct, indirect, incidental,
special, consequential, or punitive damages arising out of or in connection with your use of

KnowYouAll’s services, including but not limited to, any errors, inaccuracies, omissions, or
other defects in the information provided.
      </p>
      <p>
      7.5 Third-Party Links: KnowYouAll doesn’t contain links to third-party websites,
      applications, or services.
      </p>
      <h3>8. Intellectual Property Rights</h3>
      <p>
      8.1 Ownership: KnowYouAll retains ownership of all intellectual property rights associated
with the app, including logos, graphics, text, button icons, pictures, audio clips, data
compilations, and software. Users may interact with the AI chatbot, engage in CBT exercises,
and access articles provided by KnowYouAll. KnowYouAll does not claim ownership of user-
generated content.
      </p>
      <p>
      8.2 User Interaction and Contributions: While users engage with KnowYouAll’s AI three
interface chatbot and participate in CBT exercises, they may provide input, responses, or
personal information. KnowYouAll respects user privacy and confidentiality. Users
acknowledge that their interactions and contributions to the chatbot and CBT exercises are solely
for the purpose of personal wellbeing.
      </p>
      <h3>9. Prohibited Actions</h3>
      <p>
      9.1 Respectful and Safe Interactions: In the KnowYouAll environment, users primarily
interact with the three interface chatbot, engage in CBT exercises, and respectful and safe
interactions are paramount. Users agree not to engage in any harmful, unlawful, false,
threatening, misleading, embarrassing, harassing, abusive, defamatory, tortious, vulgar, hateful,
fraudulent, deceptive, or invasive behavior during their interactions with KnowYouAll.
      </p>
      <p>
      9.2 Inclusive Participation: Discrimination or victimization based on factors such as race,
ethnicity, religion, gender, or any other protected characteristics is strictly prohibited. Know You
All encourages inclusive participation for the sake of personal wellbeing.
</p>
<p>
9.3 Focus on Wellbeing: KnowYouAll maintains a strict policy against explicit content,
including but not limited to graphic images or videos depicting violence or sexual content. The
focus is on promoting a safe and supportive environment for users’ emotional wellbeing.
</p>
<p>
9.4 Intellectual Property and Privacy: Users shall not engage in any actions that infringe upon
the intellectual or proprietary rights of others, including copyrights and privacy rights.
Confidentiality and respect for others’ rights are fundamental principles.
</p>
<p>
9.5 Originality and Attribution: Users are encouraged to respect the intellectual property of
KnowYouAll and its content providers. In the new KnowYouAll environment, where content
posting is restricted, attributing content to the wrong source remains an inappropriate action.
Users should ensure that any interactions, including responses to the chatbot, contributions to
CBT exercises, and any references to articles, are genuine and correctly attributed within the
platform’s designated framework.
</p>
<p>
9.6 Promoting Emotional Wellbeing: KnowYouAll does not support or allow the promotion
or glorification of alcohol or drug abuse, especially to minors. The platform is dedicated to
enhancing emotional intelligence and fostering positive habits through interactions with the
chatbot, engagement in CBT exercises. Encouraging any behavior that contradicts this
commitment is strictly prohibited within the app’s confines.
</p>
<p>
9.7 Cybersecurity: Users shall not engage in any activities that compromise the security and
integrity of the platform. This includes sharing web links that contain or could contain software
viruses or similar malicious programs.
</p>
<p>
9.8 Uninterrupted Experience: Users shall not interfere with the functionality of Know You
All, ensuring that all users can have an uninterrupted and beneficial experience while interacting
with the chatbot, engaging in CBT exercises, and reading articles.
</p>
<p>
9.9 Legal Compliance: Compliance with all applicable laws, both international and local, is
mandatory while using KnowYouAll to ensure a lawful and ethical environment.
</p>
<p>
9.10 Child Privacy: Users may not share any personal information, pictures, or videos of
children without the express permission of their parents or guardians, respecting the privacy and
safety of minors.
</p>
<p>
9.11 Promoting Wellbeing: Users shall not engage in any actions that constitute or encourage
criminal behavior or negatively influence the mental health and wellbeing of others, even in the
absence of content posting.
</p>
<p>
9.12 Commercial Use: Users shall not engage in spam messages for commercial purposes or use
KnowYouAll for any commercial activities without prior written consent from KnowYouAll,
as the platform’s primary purpose is personal wellbeing.
</p>
      <h3>10. Digital Millennium Copyright Act (DMCA)</h3>
      <p>
      10.1 Copyright Infringement: If you believe that your copyrights in any content shared or
posted on KnowYouAll have been infringed, you may contact the individual responsible for the
content to resolve the issue.
</p>
<p>
10.2 Contacting KnowYouAll: If you cannot resolve the issue directly, you may contact Know
You All via email at [Contact@knowyouall.com]. Please include the following information in
your notification:
      </p>
      <ul className='termsofuselist'>
        <li>A physical or electronic signature of the copyright owner or a person authorized to act on their behalf.</li>
        <li>Identification of the copyrighted work or works claimed to have been infringed.</li>
        <li>Identification of the content that is claimed to be infringing and information reasonably sufficient to permit its location.</li>
        <li>Your contact information, including your address, telephone number, and email address.</li>
        <li>A statement by you that you have a good faith belief that use of the content in the manner complained of is not authorized by the copyright owner, its agent, or the law.</li>
        <li>A statement that the information in the notification is accurate, and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</li>
    </ul>
    <p>10.3 False Claims: Be aware that intentionally making false claims of copyright infringement
       may lead to legal consequences under the DMCA.
    </p>
      <h3>11. Termination</h3>
      <p>
      11.1 Termination by KnowYouAll: KnowYouAll reserves the right to terminate your user
account on the app without cause or notice. Such termination may result in the destruction and
loss of all information associated with your account.
      </p>
      <p>
      11.2 Misuse of Account: If you misuse your user account or engage in prohibited actions, as
outlined in these Terms of Service, KnowYouAll may temporarily suspend or permanently
terminate your account.
</p>
<p>
11.3 Content Preservation: The information associated with your account, including content
you have posted, may be deleted or preserved at KnowYouAll’s discretion. Content may be
retained if it complies with KnowYouAll’s rules and regulations.
</p>

<h3>12. Governing Law and Jurisdiction</h3>
<p>
12.1 Governing Law: These Terms of Service and your use of KnowYouAll shall be governed
by and construed in accordance with the laws of [insert governing law jurisdiction], without
regard to its conflict of law principles.
</p>
<p>
12.2 Jurisdiction: Any legal action or proceeding arising out of or relating to these Terms of
Service or your use of KnowYouAll shall be brought exclusively in the federal or state courts
located in [Michigan USA], and you consent to the jurisdiction of and venue in such courts.
</p>
      <h3>13. General Provisions</h3>
      <p>13.1 Entire Agreement: These Terms of Service constitute the entire agreement between you
and KnowYouAll and supersede all prior and contemporaneous agreements, representations,
and understandings, whether oral or written.
</p>
<p>
13.2 Waiver: The failure of KnowYouAll to exercise or enforce any right or provision of these
Terms of Service shall not constitute a waiver of such right or provision.
</p>
<p>
13.3 Severability: If any provision of these Terms of Service is held to be invalid or
unenforceable, the remaining provisions of these Terms of Service will remain in full force and
effect.
</p>
<p>13.4 Assignment: You may not assign these Terms of Service or any of your rights or
obligations under these Terms of Service without KnowYouAll’s express written consent. Know
You All may assign these Terms of Service at its sole discretion.
</p>
      <h3>14. Contact Information</h3>
      <p>
      If you have any questions or concerns about these Terms of Service or KnowYouAll’s services,
      please contact us at contact@knowyouall.com.
      </p>
      <p>
      Please note that while KnowYouAll aims to support your wellbeing, it is not a substitute for
professional help. If you are dealing with a serious mental health condition or any other illness,
such as medical or psychiatric conditions, it is essential to seek assistance from a qualified
healthcare provider or specialist. Your health and safety are of the utmost importance.
      </p>
    </div>
  );
}

export default TermsOfUse;
